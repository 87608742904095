

/* App.css */






/* for entire body background color and margin ajustments  */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #ffeb3b;
}



/* Desktop styles remain unchanged */
.main-content {
  display: flex;
  flex-direction: row;
}

.left-sidebar,
.right-sidebar {
  width: 25%;
}

.center-content {
  width: 50%;
  padding: 16px;
}



/* main body adjustments  */
.app {
  font-family: Arial, sans-serif;
  text-align: center;
  background-color: #ffeb3b;
  color: #333;
   display: flex;
   padding: 0px;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ff9800;
  padding: 10px;
  color: white;
 
}

.header-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.brand {
  font-size: 1.5rem;
}

.navbar a {
  margin: 0 15px;
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.auth-buttons button {
  background: white;
  color: #ff9800;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 0px;

}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

.mobile-nav-toggle {
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
}
.mobile-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 35%;
  height: 55vh;
  background: #ff9800;
  padding: 20px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}
.close-nav {
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  align-self: flex-end;
  cursor: pointer;
}

.nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers items horizontally */
  justify-content: center; /* Centers items vertically (if needed) */
  text-align: center; /* Ensures text alignment */
  color: red; /* Change text color */
  background-color: orange; /* Change background color */
  padding: 10px; /* Adds some spacing */
  border-radius: 0px; /* Optional rounded corners */
}
.nav-links li {
  margin: 15px 0;
}


.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.desktop-nav .nav-links {
  display: flex;
  flex-direction: row;
}



.desktop-nav .nav-links li {
  margin: 0 15px;
}

.main-content {
  display: flex;
  flex: 1;
  padding: 0px;
}


.sidebar {
  width: 10%;
  background: #ffcc80;
  padding: 10px;
}

.center-content {
  width: 60%;
  background: #ffffff;
  padding: 20px;
  border-radius: 0px;
  
  flex: 1;
  /*background: white;*/
 
}


.footer {
  background: #ff9800;
  padding:20px;
  color: white;
  margin-top: 0px;
  flex: 0;
  border-radius: 0px;
  }
  
  
  .signout-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.modal-content button {
  margin: 10px;
  padding: 8px 16px;
  cursor: pointer;
}


/* for adjustments of signout slidebar */
/* Account Dropdown */
.account-dropdown {
  position: absolute;
  background: #ff9800; /* Orange background */
  color: #ffffff;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  
  top: 50px;
  right: 0px;
  width: 220px; /* Slightly wider */
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  
  z-index: 1000;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: translateY(-10px);
  opacity: 0;
  pointer-events: none;
}

/* Show dropdown when active */
.account-dropdown.active {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}

/* Close Button */
.account-dropdown .close-btn {
  position: absolute;
  top: 5px;
  right: 8px;
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.account-dropdown .close-btn:hover {
  color: #ddd;
}

/* Dropdown Text */
.account-dropdown p {
  margin: 10px 0;
  font-size: 16px;
  font-weight: bold;
}

/* Sign-Out Button */
.account-dropdown button {
  background: #d32f2f;
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.account-dropdown button:hover {
  background: #b71c1c;
}


/* Optional: Add a hover effect */
.account-dropdown:hover {
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.4);
}


.account-button {
  background: #007bff;
  color: white;
  padding: 8px 24px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
/* Banner Container */
.banner {
  width: 100%;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  background-color: #ffcc00; /* Kid-friendly background */
  padding: 20px 0; /* Adds spacing */
}

/* Banner Image */
.banner-image {
  width: 60%; /* Default size for desktops */
  max-width: 300px; /* Prevents oversizing */
  border-radius: 15px; /* Soft edges */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Depth effect */
}

.banner-image1 {
  width: 80%; /* Default size for desktops */
  max-width: 220px; /* Prevents oversizing */
  border-radius: 15px; /* Soft edges */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Depth effect */
}










/* Responsive Design */

/* Tablets */
@media (max-width: 1024px) {
  .banner-image {
    width: 75%; /* Slightly larger for medium screens */
    max-width: 1000px;
  }
}

/* Mobile Screens */
@media (max-width: 768px) {
  .banner-image {
    width: 100%; /* Adjusted size for better viewing */
    max-width: 700px;
  }
}

/* Mobile styles */
@media (max-width: 1024px) {
  .main-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: stretch;
    gap: 0px;
  }  

  .mobile-header-sidebar,
  .mobile-footer-sidebar {
    display: flex;
    justify-content: space-around;
    padding: 0px;
    background-color: #f0f0f0;
  }

  .mobile-header-sidebar {
    order: -1; /* Places it just below the header */
  }

  .mobile-footer-sidebar {
    margin-top: auto; /* Pushes above the footer */
  }

  .center-content {
    width: 100%;
    padding: 0px;
  }
}



/* Very Small Screens */
@media (max-width: 480px) {
  .banner-image {
    width: 95%; /* Covers most of the screen */
    max-width: 400px; /* Ensures visibility */
  }
}





/*


@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }
  .sidebar.left {
    order: -1;
    width: 100%;
    text-align: center;
  }
  .sidebar.right {
    order: 1;
    width: 100%;
    text-align: center;
  }
  .main {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .app {
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .header, .footer {
    position: fixed;
    width: 100%;
    z-index: 1000;
  }
  .header {
    top: 0;
  }
  .footer {
    bottom: 0;
  }
  .sidebar.left, .sidebar.right {
    width: 100%;
  }
}
*/