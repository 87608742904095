/* Correct way to reference images from the public folder */
.signin-button-circle {
  width: 35px; /* Size of the button */
  height: 35px;
  border-radius: 50%; /* Circular shape */
  border: 0px purple; /* Google Blue border */
  background-color: white; /* White background inside */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the icon */
  transition: all 0.3s ease; /* Smooth transition on hover */
  background-image: url('pitics_logo.png'); /* Correct path to the image in the public folder */
  background-size: 30px 30px; /* Size of the icon inside the circle */
  background-repeat: no-repeat; /* Ensures the image doesn't repeat */
  background-position: center; /* Centers the image inside the button */
}

.signin-pro-text {
  position: absolute;
  top: 10px;
  left: 97%;
  transform: translateX(-50%);
  font-size: 12px;
  font-weight: bold;
}


.signin-pro-text-desktop {
  position: absolute;
  top: 10px;
  left: 99%;
  transform: translateX(-50%);
  font-size: 12px;
  font-weight: bold;
}







/* Hover effect */
.signin-button-circle:hover {
  background-color: #f0f0f0; /* Slightly darker background on hover */
}

/* Focus effect for accessibility */
.signin-button-circle:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(66, 153, 225, 0.6); /* Blue focus outline */
}

/* Pressed effect when the button is clicked */
.signin-button-circle:active {
  transform: scale(0.95); /* Shrinks the button a little */
}
